import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const BetControl = React.lazy(() => import('../views/arena/BetControl'))
const GameControl = React.lazy(() => import('../views/arena/GameControl'))
const CreateGameroom = React.lazy(() => import('../views/arena/CreateGameRoom'))
const AuthLayout = React.lazy(() => import('../components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('../components/layout/MainLayout'))
const Login = React.lazy(() => import('../views/login/Login'))
const GreenScreen = React.lazy(() => import('../components/greenScreen/GreenScreen'))

const declareRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/bet-control', name: 'Bet-Control', element: <BetControl /> },
      { path: '/game-control/:gameRoomId', name: 'Current Game', element: <GameControl /> },
      { path: '/create-gameroom', name: 'Create Gameroom', element: <CreateGameroom /> },
      {
        path: '/green-screen',
        name: 'Bets Info',
        element: <GreenScreen />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/bet-control" />,
  },
]

export default declareRoutes
