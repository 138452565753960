import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const BhistoryDownline = React.lazy(() => import('../views/reports/UserHistory'))
const Users = React.lazy(() => import('../views/users/Users'))
const Login = React.lazy(() => import('../views/login/Login'))
const AuthLayout = React.lazy(() => import('../components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('../components/layout/MainLayout'))

const csrRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/user-history', name: 'User History', element: <BhistoryDownline /> },
      { path: '/users', name: 'Update Game', element: <Users /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/user-history" />,
  },
]

export default csrRoutes
