import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const ListOfEvents = React.lazy(() => import('../views/reports/ListOfEvents'))
const EventSummary = React.lazy(() => import('../views/reports/EventSummary'))
const ListofRaces = React.lazy(() => import('../views/reports/ListOfRaces'))
const RaceSummary = React.lazy(() => import('../views/reports/RaceSummary'))
const BhistoryDownline = React.lazy(() => import('../views/reports/UserHistory'))
const Overview = React.lazy(() => import('../views/dashboard/Overview'))
const SystemConfig = React.lazy(() => import('../views/arena/SystemConfig'))
const Users = React.lazy(() => import('../views/users/Users'))
const Login = React.lazy(() => import('../views/login/Login'))
const AuthLayout = React.lazy(() => import('../components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('../components/layout/MainLayout'))
const AdminReports = React.lazy(() => import('../views/adminreports/Reports'))
const ActivityLogs = React.lazy(() => import('../views/activitylogs/AcitivityLogs'))
const ShowAdminActivityLogs = React.lazy(() =>
  import('../views/activitylogs/ShowAdminActivityLogs'),
)
const ShowUserActivityLogs = React.lazy(() =>
  import('../views/activitylogs/ShowNonAdminActivityLogs'),
)
const technicalAdminRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/overview', name: 'Overview', element: <Overview /> },
      { path: '/list-of-events', name: 'List of Events', element: <ListOfEvents /> },
      { path: '/event-summary', name: 'List of Races', element: <EventSummary /> },
      { path: '/list-of-races', name: 'List of Races', element: <ListofRaces /> },
      { path: '/race-summary', name: 'List of Races', element: <RaceSummary /> },
      { path: '/user-history', name: 'User History', element: <BhistoryDownline /> },
      { path: '/users', name: 'Update Game', element: <Users /> },
      { path: '/config', name: 'System Config', element: <SystemConfig /> },
      { path: '/company-reports', name: 'Admin Reports', element: <AdminReports /> },
      { path: '/activity-logs', name: 'Activity Logs', element: <ActivityLogs /> },
      { path: '/show-admin-activity-logs', name: 'Show Logs', element: <ShowAdminActivityLogs /> },
      { path: '/show-user-activity-logs', name: 'Show Logs', element: <ShowUserActivityLogs /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/overview" />,
  },
]

export default technicalAdminRoutes
