import React, { createContext, useContext } from 'react'
import useGetGameType from 'src/hooks/game/useGetGameType'
import useSignalrPublic from 'src/signalr/useSignalrPublic'

export const SignalRPublicContext = createContext()

export const SignalRPublicProvider = ({ children }) => {
  const { gameType, setSelectedGameType, selectedGameType } = useGetGameType()
  const { gameInfo, gameOdds, declare, payOut, lastCall } = useSignalrPublic(selectedGameType)

  return (
    <SignalRPublicContext.Provider
      value={{
        gameInfo,
        gameOdds,
        declare,
        payOut,
        gameType,
        lastCall,
        setSelectedGameType,
        selectedGameType,
      }}
    >
      {children}
    </SignalRPublicContext.Provider>
  )
}

export const useSignarRContext = () => useContext(SignalRPublicContext)
