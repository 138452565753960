import { useState, useEffect } from 'react'
import getGameType from 'src/api/config/getGameType'

const useGetGameType = () => {
  const [gameType, setGameType] = useState()
  const [selectedGameType, setSelectedGameType] = useState()
  const handleGetGameType = async () => {
    try {
      const response = await getGameType()
      setGameType(response.data)
      setSelectedGameType(response.data[0].id)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetGameType()
  }, [])

  return { gameType, setGameType, selectedGameType, setSelectedGameType }
}

export default useGetGameType
