import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import './scss/style.scss'
import ToastContainerComponent from './components/toast/ToastContainerComponent'
import { useAuth } from './hooks/auth/useAuth'
import {
  overallAdminRoutes,
  superAdminRoutes,
  technicalAdminRoutes,
  loaderRoutes,
  declareRoutes,
  auditorRoutes,
  csrRoutes,
} from './routes'

const Loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  const { token } = useAuth()
  const selectRole = (role) => {
    switch (role) {
      case 'overalladmin':
        return overallAdminRoutes
      case 'superadmin':
        return superAdminRoutes
      case 'technicaladmin':
        return technicalAdminRoutes
      case 'declare':
        return declareRoutes
      case 'loader':
        return loaderRoutes
      case 'auditor':
        return auditorRoutes
      case 'csr':
        return csrRoutes
      default:
        return overallAdminRoutes
    }
  }
  const role = selectRole(token?.roles && token.roles[0].roleName.toLowerCase())
  const routesResult = useRoutes(role)

  return (
    <>
      <Suspense fallback={<Loading />}>{routesResult}</Suspense>
      <ToastContainerComponent />
    </>
  )
}

export default App
